import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IEmbedModalProperties,
  IEmbedModalState,
} from './embed-modal-interface';
import './embed-modal.scss';
export default class EmbedModal extends BaseComponent<
  IEmbedModalProperties,
  IEmbedModalState
> {
  public constructor(props: IEmbedModalProperties) {
    super(props);

    this.state = {
      copySnippetText: this.translate('copySnippet'),
    };
  }

  protected declareTranslateCollection(): string | undefined {
    return 'embedModal';
  }

  private onCopySnippet(): void {
    const { embeddedLink } = this.props;

    const publicEmbedLink = `${window.location.protocol}//${window.location.host}${embeddedLink}`;
    const iframeLink = `<iframe src=${publicEmbedLink} width="370" height="100%" frameBorder="0"></iframe>`;
    navigator.clipboard.writeText(iframeLink).then((): void => {
      this.setState({
        copySnippetText: this.translate('copied'),
      });
    });
  }

  private onDownloadImageClick(): void {
    const { imageSource } = this.props;

    window.open(imageSource, '_blank').focus();
  }

  public render(): ReactNode {
    const { closeEmbedModal, showModal, embeddedLink } = this.props;
    const showHideClassName = showModal
      ? 'c-modal display-block'
      : 'c-modal display-none';

    let publicEmbedLink = '';
    //check if document defined.(prevent issue on SSR)
    const isBrowser = typeof document !== 'undefined';
    if (isBrowser) {
      showModal
        ? document.getElementsByTagName('html')[0].classList.add('is-fixed')
        : document.getElementsByTagName('html')[0].classList.remove('is-fixed');

      publicEmbedLink = `${window.location.protocol}//${window.location.host}${embeddedLink}`;
    }

    const iframeLink = `<iframe src=${publicEmbedLink} width="370" height="100%" frameBorder="0"></iframe>`;

    return (
      <div className={showHideClassName}>
        <div className="c-modal-main">
          <div className="c-state-info-section">
            <iframe
              src={embeddedLink}
              width="358"
              height="500"
              frameBorder="0"
            ></iframe>
          </div>
          <div className="c-embed-section">
            <div className="c-embed__head">
              <h3 className="c-embed__title">
                {this.translate('embeddedPreview')}
              </h3>
              <button onClick={closeEmbedModal} aria-label="Close">
                <SvgIcon
                  width="32"
                  viewBox="0 0 32 32"
                  circles={[{ cx: '16', cy: '16', r: '16', fill: '#fff' }]}
                  paths={[
                    { command: SvgIconPathCommand.CLOSE, fill: '#4B4950' },
                  ]}
                />
              </button>
            </div>

            <div className="c-embed-iframe">
              <textarea readOnly>{iframeLink}</textarea>
            </div>
            <div className="row c-embed-btn__wrap">
              <div className="col-md-6 c-embed-btn__col">
                <button
                  className="c-btn is-purple has-left-icon"
                  onClick={(): void => {
                    this.onCopySnippet();
                  }}
                >
                  <SvgIcon
                    width="21"
                    viewBox="0 0 21 20"
                    paths={[
                      {
                        command: SvgIconPathCommand.COPY_SNIPPET,
                        fill: '#fff',
                      },
                    ]}
                  />
                  {this.state.copySnippetText}
                </button>
              </div>
              <div className="col-md-6 c-embed-btn__col">
                <button
                  className="c-btn is-white has-left-icon"
                  onClick={(): void => {
                    this.onDownloadImageClick();
                  }}
                >
                  <SvgIcon
                    width="20"
                    viewBox="0 0 20 20"
                    paths={[
                      { command: SvgIconPathCommand.DOWNLOAD, fill: '#4A1D96' },
                    ]}
                  />
                  {this.translate('downloadImage')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
